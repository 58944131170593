<template>
    <v-text-field
        prepend-inner-icon="icons8-search"
        :placeholder="placeholder"
        v-model="innerValue"
        filled
        rounded
        :class="customclass"
        :background-color="background"
        hide-details="auto"
        clearable
        @click:clear="clear()">
    </v-text-field>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        background: {
            type: String,
            default: "grey lighten-4",
        },
        customclass: {
            type: String,
            default: "rounded-of",
        }
    },
    data() {
        return {
            innerValue: "",
        };
    },
    methods: {
        clear() {
            setTimeout(() => {
                this.innerValue = "";
                sessionStorage.clear()
            }, 1);
        },
    },    
    watch: {
        innerValue: function (value) {
            this.$emit("input", value);
        },
    },
    mounted() {
        this.innerValue = this.value;
    },
};
</script>

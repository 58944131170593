import { render, staticRenderFns } from "./DiningView.vue?vue&type=template&id=2bf78392&"
import script from "./DiningView.vue?vue&type=script&lang=js&"
export * from "./DiningView.vue?vue&type=script&lang=js&"
import style0 from "./DiningView.vue?vue&type=style&index=0&id=2bf78392&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
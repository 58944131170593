<template>
  <div class="paddingmobile">
    <div class="d-flex justify-center align-center pt-4">
      <v-img
        @click="MIX_go('/home')"
        src="@/assets/Big-Heart-Logo-Red.jpg"
        contain
        class="headericon"
      />
    </div>
    <v-container>
      <v-overlay :opacity="0.3" :value="loading" color="lightgrey">
        <v-progress-circular indeterminate size="64" color="primary">
        </v-progress-circular>
      </v-overlay>
      <v-row class="dense">
        <v-col cols="12" xs="12" sm="12" class="">
          <FarmCard>
            <!-- <template v-slot:header>Title</template> -->
            <v-row class="px-1" dense>
              <v-col cols="12">
                <div class="title text-center pb-5">
                  {{ $t("welcome") }} <b>{{ userFirstName }}</b>
                </div>
                <SearchField
                  :placeholder="$t('search') + '...'"
                  v-model="search"
                  background="lightgrey"
                />
              </v-col>
            </v-row>
          </FarmCard>
        </v-col>
        <v-col cols="6">
          <div class="title pl-4">{{ $t("whatson") }}</div>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <EventFilterDrawer v-on:filterSubmit="getFilteredEvents" />
        </v-col>
        <div v-if="events.length <= 0" class="subtitle-4 black--text pl-6">
          {{ $t("noresult") }}
        </div>
        <v-col
          v-for="(event, index) in events"
          :key="index"
          cols="12"
          xs="12"
          sm="6"
          class=""
        >
          <EventCard :datadown="event"> </EventCard>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "WhatsOnView",
  data: () => ({
    loading: false,
    events: [],
    search: "",
    sortby: "",
    userFirstName: "",
    form: {
      name: "",
      email: "",
      password: "",
      testType: "",
      date: "",
      time: "",
    },
    items: [
      { name: "Item 1", v: "value 1" },
      { name: "Item 2", v: "value 2" },
      { name: "Item 3", v: "value 3" },
      { name: "Item 4", v: "value 4" },
    ],
  }),
  computed: {
    ...mapGetters({
      GET_showFilterDrawer: "GET_showFilterDrawer",
      GET_currentUserProfile: "GET_currentUserProfile",
    }),
    computedSearch() {
      var onlyLetters = /^[a-zA-Z][a-zA-Z]*[a-zA-Z]$/;
      if (this.search.length > 1 && onlyLetters.test(this.search)) {
        // console.log(this.search)
        if (this.$dayjs(this.search, "MMMM", true).isValid()) {
          //console.log(this.MIX_formatDateTime(this.search, 'MMMM', 'unix'))
          return this.MIX_formatDateTime(this.search, "MMMM", "unix");
        } else {
          return this.search.trim() + "*";
        }
      } else {
        return "";
      }
    },
  },
  components: {
    FarmCard: () => import("@/components/card/FarmCard.vue"),
    EventCard: () => import("@/components/card/EventCard.vue"),
    EventFilterDrawer: () => import("@/components/filter/EventFilter.vue"),
  },
  methods: {
    ...mapActions({
      ACT_showFilterDrawer: "ACT_showFilterDrawer",
    }),
    updateShowFilterDrawer() {
      this.ACT_showFilterDrawer(!this.GET_showFilterDrawer);
    },
    async initialise() {
      this.loading = true;
      this.getUser();
      await this.getEvents();
      this.loading = false;
    },
    async getEvents() {
      return new Promise((resolve, reject) => {
        this.REDIS_searchFor(
          "event",
          "",
          "",
          "eventDateTime",
          "asc",
          `${this.computedSearch} @deleted:{false} @approvalStatus:{true}`
        )
          .then((result) => {
            let tempEventsArray = [];

            // console.log('RESULTS', result.data.documents);
            // for each event, check if the eventDates array is more than 1
            // if it is, then iterate through each date and append the event to the events array
            // if it is not, then append the event to the events array

            for (let i = 0; i < result.data.documents.length; i++) {
              if (result.data.documents[i].eventDates !== null) {
                for (
                  let j = 0;
                  j < result.data.documents[i].eventDates.length;
                  j++
                ) {
                  let event = result.data.documents[i];
                  let event2 = {
                    ...result.data.documents[i],
                    eventDateTime: event.eventDates[j],
                  };
                  //event.eventDateTime = event.eventDates[j];
                  if (
                    event2.eventDateTime > this.$dayjs().format("YYYY-MM-DD")
                  ) {
                    tempEventsArray.push(event2);
                  }
                }
              } else {
                if (
                  result.data.documents[i].eventDateTime >
                  this.$dayjs().format("YYYY-MM-DD")
                ) {
                  tempEventsArray.push(result.data.documents[i]);
                }
              }
            }
            // Sort the Array by eventDateTime
            tempEventsArray = tempEventsArray.sort((a, b) =>
              a.eventDateTime > b.eventDateTime ? 1 : -1
            );
            this.events = tempEventsArray;
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getFilteredEvents(sortby) {
      this.loading = true;
      if (sortby === "createdDateTime") {
        this.REDIS_searchFor(
          "event",
          "",
          "",
          "createdDateTime",
          "desc",
          `${this.computedSearch} @deleted:{false} @approvalStatus:{true}`
        )
          .then((result) => {
            let tempEventsArray = [];
            for (let i = 0; i < result.data.documents.length; i++) {
              if (result.data.documents[i].eventDates !== null) {
                for (
                  let j = 0;
                  j < result.data.documents[i].eventDates.length;
                  j++
                ) {
                  let event = result.data.documents[i];
                  let event2 = {
                    ...result.data.documents[i],
                    eventDateTime: event.eventDates[j],
                  };
                  //event.eventDateTime = event.eventDates[j];
                  if (
                    event2.eventDateTime > this.$dayjs().format("YYYY-MM-DD")
                  ) {
                    tempEventsArray.push(event2);
                  }
                }
              } else {
                if (
                  result.data.documents[i].eventDateTime >
                  this.$dayjs().format("YYYY-MM-DD")
                ) {
                  tempEventsArray.push(result.data.documents[i]);
                }
              }
            }

            tempEventsArray = tempEventsArray.filter(
              (event) =>
                this.$dayjs(event.createdDateTime).format("YYYY-MM-DD") <=
                  this.$dayjs().format("YYYY-MM-DD") &&
                event.eventDateTime !== null
            );
            // Sort the Array by createdDateTime
            tempEventsArray = tempEventsArray.sort((a, b) =>
              a.createdDateTime < b.createdDateTime ? 1 : -1
            );
            this.loading = false;
            this.events = tempEventsArray;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else if (sortby === "eventDateTime") {
        this.REDIS_searchFor(
          "event",
          "",
          "",
          "eventDateTime",
          "asc",
          `${this.computedSearch} @deleted:{false} @approvalStatus:{true}`
        )
          .then((result) => {
            let tempEventsArray = [];
            for (let i = 0; i < result.data.documents.length; i++) {
              if (result.data.documents[i].eventDates !== null) {
                for (
                  let j = 0;
                  j < result.data.documents[i].eventDates.length;
                  j++
                ) {
                  let event = result.data.documents[i];
                  let event2 = {
                    ...result.data.documents[i],
                    eventDateTime: event.eventDates[j],
                  };
                  if (
                    event2.eventDateTime > this.$dayjs().format("YYYY-MM-DD")
                  ) {
                    tempEventsArray.push(event2);
                  }
                }
              } else {
                if (
                  result.data.documents[i].eventDateTime >
                  this.$dayjs().format("YYYY-MM-DD")
                ) {
                  tempEventsArray.push(result.data.documents[i]);
                }
              }
            }

            tempEventsArray = tempEventsArray.filter(
              (event) => event.eventDateTime !== null
            );
            // Sort the Array by eventDateTime
            tempEventsArray = tempEventsArray.sort((a, b) =>
              a.eventDateTime > b.eventDateTime ? 1 : -1
            );
            this.events = tempEventsArray;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else if (sortby === "") {
        this.REDIS_searchFor(
          "event",
          "",
          "",
          "eventDateTime",
          "desc",
          `${this.computedSearch} @deleted:{false} @approvalStatus:{true}`
        )
          .then((result) => {
            let tempEventsArray = [];
            for (let i = 0; i < result.data.documents.length; i++) {
              if (result.data.documents[i].eventDates !== null) {
                for (
                  let j = 0;
                  j < result.data.documents[i].eventDates.length;
                  j++
                ) {
                  let event = result.data.documents[i];
                  let event2 = {
                    ...result.data.documents[i],
                    eventDateTime: event.eventDates[j],
                  };

                  tempEventsArray.push(event2);
                }
              } else {
                tempEventsArray.push(result.data.documents[i]);
              }
            }

            tempEventsArray = tempEventsArray.filter(
              (event) => event.eventDateTime !== null
            );
            //Sort the events by eventDateTime, descending
            tempEventsArray = tempEventsArray.sort((a, b) =>
              b.eventDateTime > a.eventDateTime ? 1 : -1
            );
            this.events = tempEventsArray;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    },
    getUser() {
      if (this.GET_currentUserProfile) {
        this.userFirstName = this.GET_currentUserProfile.firstName;
      } else if (localStorage.userToken) {
        // console.log("No user profile found, using local storage")
        //console.log(localStorage.userToken)
        this.userFirstName = this.MIX_tokenToUser(
          localStorage.userToken
        ).given_name;
      } else {
        this.userFirstName = "Guest";
      }
    },
  },
  watch: {
    search: {
      handler: function () {
        this.initialise();
      },
      deep: true,
    },
  },
  mounted() {
    // const collection = "org";
    // const entityId = "01GTEMK1R88QM1PM3CT5W0ZSZ1";
    // console.log(this.REDIS_read(collection, entityId));
  },
  created() {
    this.initialise();
  },
};
</script>

<style>
.noboxshadow {
  box-shadow: none !important;
}
.v-carousel__controls {
  margin-top: 200px !important;
}
</style>

<template>
  <div class="">
    <small class="label">{{ label }} ({{ innerValue || "TBC" }})</small>
    <div class="time-picker-wrapper lightgrey">
      <v-select
        label="Hours"
        :items="hours"
        v-model="selectedHour"
        solo
        dense
        class="hours"
        background-color="lightgrey"
        hide-details="auto"
        :menu-props="offset"
        :attach="true"
        clearable
        @click:clear="clearHours()"
      >
      </v-select>

      <v-select
        label="Mins"
        :items="mins"
        v-model="selectedMinutes"
        solo
        dense
        class="mins"
        background-color="lightgrey"
        hide-details="auto"
        :menu-props="offset"
        :attach="true"
        clearable
        @click:clear="clearMins()"
      >
      </v-select>
      <v-select
        label="Format"
        :items="['am', 'pm']"
        v-model="timePeriod"
        solo
        dense
        class="format"
        background-color="lightgrey"
        hide-details="auto"
        :menu-props="offset"
        :attach="true"
        @click:clear="clearMins()"
      >
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "grey lighten-4",
    },
    hours: {
      type: Array,
      default: () => ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    },
    mins: {
      type: Array,
      default: () => ["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"],
    },
    offset: {
      type: Object,
      default: () => {
        return { top: false, offsetY: true };
      },
    },
  },
  data: () => ({
    selectedHour: "",
    selectedMinutes: "",
    timePeriod: "am",
  }),
  mounted() {
    if (this.value) {
      this.parseTimeString(this.value);
    }
  },
  computed: {
    innerValue() {
      if (!this.selectedHour) {
        return "";
      } else if (!this.selectedMinutes) {
        return `${this.selectedHour}:00${this.timePeriod}`;
      } else {
        return `${this.selectedHour}:${this.selectedMinutes}${this.timePeriod}`;
      }
    },
  },
  methods: {
    parseTimeString(time) {
      if (time !== "TBC") {
        const match = time.match(/^(\d{1,2}):(\d{2})([ap]m)$/i) || [];
        if (match.length === 0) {
          // eslint-disable-next-line no-unused-vars
          const [_, hours, format] = time.match(/^(\d{1,2})([ap]m)$/i);
          this.selectedHour = hours || null;
          this.selectedMinutes = "00" || null;
          this.timePeriod = format || null;
        } else {
          // eslint-disable-next-line no-unused-vars
          const [_, hours, mins, format] = match;
          this.selectedHour = hours || null;
          this.selectedMinutes = mins || null;
          this.timePeriod = format || null;
        }
      }
    },
    clearHours() {
      setTimeout(() => {
        this.selectedHour = null;
        this.selectedMinutes = null;
      }, 1);
    },
    clearMins() {
      setTimeout(() => {
        this.selectedMinutes = null;
      }, 1);
    },
  },
  watch: {
    // Update Parent v-model
    innerValue: function (value) {
      this.$emit("input", value);
    },
    value(newVal) {
      if (newVal) {
        this.parseTimeString(newVal);
      }
    },
  },
};
</script>

<style scoped>
/* ::v-deep .v-label {
  font-size: 0.65rem;
} */

::v-deep .v-select {
  border-radius: 0;
}

.time-picker-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 10px;

  .hours,
  .mins {
    flex: 4;
  }

  .format {
    flex: 1;
  }
}
</style>

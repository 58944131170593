<template>
  <div class="paddingmobile" v-if="MIX_isLoggedIn()">
    <div class="d-flex justify-center align-center pt-4">
      <v-img
        @click="MIX_go('/home')"
        src="@/assets/Big-Heart-Logo-Red.jpg"
        contain
        class="headericon"
      />
    </div>
    <v-container>
      <v-overlay :opacity="0.3" :value="loading" color="lightgrey">
        <v-progress-circular indeterminate size="64" color="primary"> </v-progress-circular>
      </v-overlay>
      <v-row class="dense">
        <v-col cols="12" xs="12" sm="12" class="">
          <FarmCard>
            <!-- <template v-slot:header>Title</template> -->
            <v-row class="px-1" dense>
              <v-col cols="12">
                <div class="title text-center pb-5">
                  {{ $t("welcome") }} <b>{{ userFirstName }}</b>
                </div>
                <SearchField
                  :placeholder="$t('search') + '...'"
                  v-model="search"
                  background="lightgrey"
                />
              </v-col>
            </v-row>
          </FarmCard>
        </v-col>
        <v-col cols="12">
          <div class="title pl-4">{{ $t("latestoffers") }}</div>
        </v-col>
        <div v-if="offers.length <= 0" class="subtitle-4 black--text pl-6">
          {{ $t("noresult") }}
        </div>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          v-for="(offer, index) in availableOffers"
          :key="index"
          class=""
        >
          <OfferCard :datadown="offer" claimbutton>
            <template v-slot:toptitle>{{ offer.offer }}</template>
            <template v-slot:bottomtitle>{{ offer.store }}</template>
          </OfferCard>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OfferView",
  data: () => ({
    loading: false,
    offers: [],
    userOfferList: [],
    search: "",
    userFirstName: "",
    form: {
      name: "",
      email: "",
      password: "",
      testType: "",
      date: "",
      time: "",
    },
    stores: [],
  }),
  components: {
    FarmCard: () => import("@/components/card/FarmCard.vue"),
    OfferCard: () => import("@/components/card/OfferCard.vue"),
  },
  computed: {
    ...mapGetters({
      GET_currentUserProfile: "GET_currentUserProfile",
    }),
    computedSearch() {
      var onlyLetters = /^[a-zA-Z][a-zA-Z]*[a-zA-Z]$/;
      if (this.search.length > 2 && onlyLetters.test(this.search)) {
        return this.search.trim() + "*";
      } else {
        return "";
      }
    },
    availableOffers() {
      return this.offers.filter((offer) => !this.userOfferList.includes(offer.entityId));
    },
  },
  methods: {
    async initialise() {
      this.loading = true;
      this.getUser();
      await this.getOffers();
      await this.getOrgs();
      await this.getUserOffers();
      this.loading = false;
      //console.log(JSON.stringify(this.offers, null, 2))
      //console.log(this.stores[1].name)
    },
    async getOffers() {
      return new Promise((resolve, reject) => {
        // this.REDIS_searchFor("offer", "", "", "createdDateTime", "desc", `${this.computedSearch} @deleted:{false}`)
        this.REDIS_searchFor("offer", "", "", "createdDateTime", "desc", `@deleted:{false}`)
          .then((result) => {
            this.offers = result.data.documents;
            // console.log("Offers: ", this.offers);
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // Searches for store coupons
    async getOrgs() {
      if (this.computedSearch !== "") {
        return new Promise((resolve, reject) => {
          this.REDIS_searchFor("org", "", "", "", "", `${this.computedSearch} @deleted:{false}`)
            .then((result) => {
              this.stores = result.data.documents;
              // console.log("StoresAll: ", this.stores) // searched store info
              this.stores = this.stores.map((store) => store.entityId);
              // console.log("StoresE-ID: ", this.stores) // return entityID only
              this.offers = this.offers.filter((offer) => this.stores.includes(offer.orgId));
              // console.log('Filtered Offers:', this.offers)
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    async getUserOffers() {
      let useroffer = await this.REDIS_searchFor(
        "user-offer",
        "",
        "",
        "",
        "",
        `@userId:{${this.userEntityId}} @status:{unclaimed}`
      );
      this.userOfferList = useroffer.data.documents.map((offer) => offer.offerId);
      // let test = useroffer.data.documents.map((offer) => offer.offerId);
      // console.log("User offers", this.userOfferList);
    },
    getUser() {
      if (this.GET_currentUserProfile != null) {
        this.userFirstName = this.GET_currentUserProfile.firstName;
        this.userEntityId = this.GET_currentUserProfile.entityId;
      } else if (localStorage.userToken) {
        // console.log("No user profile found, using local storage")
        //console.log(localStorage.userToken)
        this.userFirstName = this.MIX_tokenToUser(localStorage.userToken).given_name;
      } else {
        this.userFirstName = "Guest";
      }
    },
  },
  watch: {
    search: {
      handler: function () {
        this.initialise();
      },
      deep: true,
    },
  },
  created() {
    this.initialise();
  },
};
</script>

<style>
.noboxshadow {
  box-shadow: none !important;
}

.v-carousel__controls {
  margin-top: 200px !important;
}
</style>

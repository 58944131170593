<template>
  <div class="paddingmobile">
    <div class="d-flex justify-center align-center pt-4">
      <v-img
        @click="MIX_go('/home')"
        src="@/assets/Big-Heart-Logo-Red.jpg"
        contain
        class="headericon"
      />
    </div>
    <v-container>
      <v-overlay :opacity="0.3" :value="loading" color="lightgrey">
        <v-progress-circular indeterminate size="64" color="primary"> </v-progress-circular>
      </v-overlay>
      <v-row class="dense">
        <v-col cols="12" xs="12" sm="12" class="">
          <FarmCard>
            <!-- <template v-slot:header>Title</template> -->
            <v-row class="px-1" dense>
              <v-col cols="12">
                <div class="title text-center pb-5">
                  {{ $t("welcome") }} <b>{{ userFirstName }}</b>
                </div>
                <SearchField
                  :placeholder="$t('search') + '...'"
                  v-model="search"
                  background="lightgrey"
                />
              </v-col>
            </v-row>
          </FarmCard>
        </v-col>
        <v-col cols="6">
          <div class="title pl-4">{{ $t("diningout") }}</div>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <DiningFilter v-on:filterSubmit="getFilteredOrg" />
        </v-col>
        <!-- <v-col cols="12" xs="12" sm="6"  v-for="(store, index) in $sampledata.stores" :key="index"> -->
      </v-row>
      <v-row class="dense">
        <div v-if="stores.length <= 0" class="subtitle-4 black--text pl-6">
          {{ $t("noresult") }}
        </div>
        <v-col
          cols="12"
          xs="12"
          sm="6"
          v-for="(store, index) in stores"
          :key="index"
          style="flex: none !important"
        >
          <!-- <div>GENERATED</div> v-if="store.icon == 'icons8-cutlery'" -->
          <!-- {{ store }} -->
          <StoreCard :datadown="store">
            <template v-slot:toptitle>{{ store.name }}</template>
            <!-- <template v-slot:icon-name>{{ store.type }}</template> -->
            <template v-slot:storestatus>{{ store.businessStatus }}</template>
          </StoreCard>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import { stores } from '@/sampleData.js';
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DiningView",
  data: () => ({
    loading: false,
    search: "",
    userFirstName: "",
    form: {
      name: "",
      email: "",
      password: "",
      testType: "",
      date: "",
      time: "",
    },
    items: [
      { name: "Item 1", v: "value 1" },
      { name: "Item 2", v: "value 2" },
      { name: "Item 3", v: "value 3" },
      { name: "Item 4", v: "value 4" },
    ],
    diningData: [],
    stores: [],
  }),
  components: {
    FarmCard: () => import("@/components/card/FarmCard.vue"),
    StoreCard: () => import("@/components/card/StoreCard.vue"),
    DiningFilter: () => import("@/components/filter/DiningFilter.vue"),
  },
  computed: {
    ...mapGetters({
      GET_showFilterDrawer: "GET_showFilterDrawer",
      GET_currentUserProfile: "GET_currentUserProfile",
    }),
    computedDiningStore() {
      let diningData = [];

      diningData = this.stores.filter(function (dd) {
        return (
          dd.type == "restaurant" ||
          dd.type === "bar" ||
          dd.type === "hospitality" ||
          dd.type === "Hospitality"
        );
      });

      return diningData;
    },
    computedSearch() {
      var onlyLetters = /^[a-zA-Z][a-zA-Z]*[a-zA-Z]$/;
      if (this.search.length > 1 && onlyLetters.test(this.search)) {
        return this.search.trim() + "*";
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapActions({
      ACT_showFilterDrawer: "ACT_showFilterDrawer",
    }),
    updateShowFilterDrawer() {
      this.ACT_showFilterDrawer(!this.GET_showFilterDrawer);
    },
    async initialise() {
      this.loading = true;
      this.getUser();
      await this.getOrgs();
      // this.diningData = this.stores.filter( function(dd) { return dd.type == "restaurant" || dd.type === "bar" })
      //console.log(this.diningData)
      this.loading = false;
      //console.log(JSON.stringify(this.stores, null, 2))
      //console.log(this.stores[1].name)
    },
    async getOrgs() {
      return new Promise((resolve, reject) => {
        this.REDIS_searchFor(
          "org",
          "",
          "",
          "random",
          "desc",
          `${this.computedSearch} @type:hospitality @businessStatus:Occupied @deleted:{false}`
        )
          .then((result) => {
            // console.log(result)
            this.stores = result.data.documents;
            // this.stores.forEach((store) => {
            //   console.log(store.businessTags)
            // })
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getFilteredOrg(dietPreference, diningLocation, diningCountry) {
      this.loading = true;
      // console.log("Distance: " + distance)
      // console.log("Business Type: " + businessType)
      let tagQuery = "";
      if (dietPreference || diningLocation !== "") {
        if (dietPreference && diningLocation !== "" && diningCountry !== "") {
          tagQuery = `@businessTags:{${dietPreference}} @businessTags:{${diningLocation}} @businessTags:{${diningCountry}}`;
        } else if (dietPreference && diningLocation !== "") {
          tagQuery = `@businessTags:{${dietPreference}} @businessTags:{${diningLocation}}`;
        } else if (dietPreference !== "") {
          tagQuery = `@businessTags:{${dietPreference}}`;
        } else if (diningLocation !== "") {
          tagQuery = `@businessTags:{${diningLocation}}`;
        }
      }
      // console.log("Tag Query: " + tagQuery)
      await this.REDIS_searchFor(
        "org",
        "",
        "",
        "random",
        "desc",
        `${this.computedSearch} ${tagQuery} @type:hospitality @businessStatus:Occupied @deleted:{false}`
      )
        .then((result) => {
          this.stores = result.data.documents;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getUser() {
      if (this.GET_currentUserProfile) {
        this.userFirstName = this.GET_currentUserProfile.firstName;
      } else if (localStorage.userToken) {
        // console.log("No user profile found, using local storage")
        //console.log(localStorage.userToken)
        this.userFirstName = this.MIX_tokenToUser(localStorage.userToken).given_name;
      } else {
        this.userFirstName = "Guest";
      }
    },
  },
  watch: {
    search: {
      handler: function () {
        this.initialise();
      },
      deep: true,
    },
  },
  created() {
    this.initialise();
  },
};
</script>

<style>
.noboxshadow {
  box-shadow: none !important;
}
.v-carousel__controls {
  margin-top: 200px !important;
}
</style>

<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        dense
        filled
        rounded
        v-model="computedDate"
        class="rounded-lg"
        :background-color="background"
        :label="label"
        hide-details="auto"
        append-icon="icons8-timeline-week"
        readonly
        v-bind="attrs"
        v-on="on"
      >
      </v-text-field>
      <!-- v-model="innerValue" -->
    </template>
    <v-date-picker
      :min="min"
      v-model="date1"
      no-title
      @input="dateMenu = false"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    background: {
      type: String,
      default: "grey lighten-4",
    },
    min: {
      type: String,
      default: "",
    },
  },
  data: (vm) => ({
    innerValue: "",
    dateMenu: false,
    dataGrabbed: false,
    // Shown today's date in 'Event Date' input box
    date1: vm.MIX_formatDateTimeNow("YYYY-MM-DD"),
  }),
  computed: {
    // Computed Date
    // Shown today's date in 'Event Date' input box on form until chnaged by user
    computedDate() {
      return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YYYY");
    },
  },
  watch: {
    // Dates
    dateMenu() {
      // Gets unix date of selected date (Number)
      this.innerValue = this.MIX_formatDateTime(
        this.date1,
        "YYYY-MM-DD",
        "unix"
      );
      //   console.log("WATCH =>", this.innerValue);
    },
    // Update Parent v-model
    innerValue: function (value) {
      // Changes unix date to String
      this.$emit("input", value);
      //   console.log("INNER VALUE =>", value);
    },
    value: function (value) {
      //1697238000
      // console.log("VALUE =>", value.toString().length, value)
      if (!this.dataGrabbed && value.toString().length !== 10) {
        // Takes updated date and feeds it back to input box display if not a unix number date
        this.date1 = this.MIX_formatDateTime(
          value,
          "YYYY-MM-DDTHH:mm:ssZ[Z]",
          "YYYY-MM-DD"
        );
        this.dataGrabbed = true;
        // console.log("DATE-1", this.date1);
      }
    },
  },
  mounted() {
    this.innerValue = this.value;
    // console.log("MOUNTED =>", this.innerValue);
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paddingmobile"},[_c('v-overlay',{attrs:{"opacity":0.1,"value":_vm.loading,"color":"lightgrey"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_c('div',{staticClass:"d-flex justify-center align-center pt-4"},[_c('v-img',{staticClass:"headericon",attrs:{"src":require("@/assets/Big-Heart-Logo-Red.jpg"),"contain":""},on:{"click":function($event){return _vm.MIX_go('/admin/dashboard')}}})],1),_c('div',{staticClass:"pl-10 pr-10"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"title font-weight-black primary--text"},[_vm._v(" "+_vm._s(_vm.$t("offers"))+" ")])])],1),(_vm.GET_currentUserProfile.level === 'shopadmin')?_c('v-row',{staticClass:"dense lightgrey rounded-of mb-6 pt-3 justify-space-between"},[_c('v-col',{attrs:{"cols":"8","xs":"6","sm":"6"}},[_c('SearchField',{staticStyle:{"padding-left":"1em","max-width":"17em"},attrs:{"placeholder":"Search","background":"white"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end",staticStyle:{"padding-right":"1.5em"},attrs:{"cols":"4","xs":"6","sm":"6"}},[_c('AddOffer',{key:_vm.addOfferKey,staticStyle:{"margin-right":"0.25em"},on:{"addedOffer":_vm.initialise}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"title pl-4",staticStyle:{"margin-top":"-0.6em","margin-bottom":"-1.1em"}},[_vm._v(" "+_vm._s(_vm.$t("youroffers"))+" ")])]),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12"}},[_c('carousel',{attrs:{"height":"auto","paginationPadding":4,"perPage":1,"perPageCustom":[
            [768, 1],
            [1024, 2],
          ],"paginationActiveColor":"#CC0200","paginationColor":"#D9D9D9"}},_vm._l((_vm.storeoffers),function(offer,index){return _c('slide',{key:index},[_c('OfferCard',{staticClass:"lightgrey",attrs:{"datadown":offer,"editbutton":"","deletebutton":"","adminonly":""},on:{"edited":function($event){return _vm.initialise()}},scopedSlots:_vm._u([{key:"toptitle",fn:function(){return [_vm._v(_vm._s(offer.offer))]},proxy:true},{key:"bottomtitle",fn:function(){return [_vm._v(_vm._s(offer.store))]},proxy:true}],null,true)})],1)}),1)],1)],1):_vm._e(),_c('v-row',{staticClass:"dense lightgrey rounded-of mb-6 pt-3"},[_c('v-col',{attrs:{"cols":"8","xs":"6","sm":"6"}},[_c('SearchField',{staticStyle:{"padding-left":"1em","max-width":"17em"},attrs:{"placeholder":"Search","background":"white"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end",staticStyle:{"padding-right":"1.5em"},attrs:{"cols":"4","xs":"6","sm":"6"}},[(_vm.GET_currentUserProfile.level === 'superadmin')?_c('AddOffer',{staticStyle:{"margin-right":"0.25em"},on:{"addedOffer":_vm.initialise}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"title pl-4",staticStyle:{"margin-top":"-0.6em","margin-bottom":"-1.1em"}},[_vm._v(" "+_vm._s(_vm.$t("communityoffers"))+" ")])]),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12"}},[_c('carousel',{attrs:{"height":"auto","paginationPadding":4,"perPage":1,"perPageCustom":[
            [768, 1],
            [1024, 2],
          ],"paginationActiveColor":"#CC0200","paginationColor":"#D9D9D9"}},_vm._l((_vm.offers),function(offer,index){return _c('slide',{key:index},[_c('OfferCard',{staticClass:"lightgrey",attrs:{"datadown":offer,"editbutton":"","deletebutton":""},on:{"edited":function($event){return _vm.initialise()},"deleted":function($event){return _vm.initialise()}},scopedSlots:_vm._u([{key:"toptitle",fn:function(){return [_vm._v(_vm._s(offer.offer))]},proxy:true},{key:"bottomtitle",fn:function(){return [_vm._v(_vm._s(offer.store))]},proxy:true}],null,true)})],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
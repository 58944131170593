<template>
    <div class="paddingmobile" v-if="MIX_isLoggedIn()">
        <div class="d-flex justify-center align-center pt-4">
            <v-img @click="MIX_go('/home')" src="@/assets/Big-Heart-Logo-Red.jpg" contain class="headericon" />
        </div>
        <v-container>
            <v-overlay
          :opacity="1"
          :value="loading"
          color="lightgrey"
        >
        <v-progress-circular indeterminate size="64" color="primary">
          </v-progress-circular>
        </v-overlay>
            <v-row class="dense">
                <v-col cols="12" xs="12" sm="12" class="">
                    <FarmCard>
                        <!-- <template v-slot:header>Title</template> -->
                        <v-row class="px-1" dense>
                            <v-col cols="12">
                                <div class="title text-center pb-5">{{ $t("welcome") }} <b>{{ userFirstName }}</b></div>
                                <SearchField :placeholder="$t('search')+ '...'" v-model="search" background="lightgrey" />
                            </v-col>
                        </v-row>
                    </FarmCard>
                </v-col>
                <v-col cols="12">
                    <div class="title pl-4">{{ $t("myoffers") }}</div>
                    <div v-if="offers.length <= 0" class="subtitle pl-4">{{ $t("nooffers") }}</div>
                </v-col>
                <div v-if="offers.length <= 0" class="subtitle-4 black--text pl-6">{{ $t("noresult") }}</div>
                <v-col cols="12" xs="12" sm="6" v-for="(offer, index) in availableOffers" :key="index" class="">
                        <UserOfferCard :datadown="offer" v-on:usedOffer="initialise()">
                            </UserOfferCard>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "OfferView",
    data: () => ({
        loading: false,
        offers: [],
        availableOffers: [],
        search: "",
        userFirstName: "",
        form: {
            name: "",
            email: "",
            password: "",
            testType: "",
            date: "",
            time: "",
        },
    }),
    components: {
        FarmCard: () => import("@/components/card/FarmCard.vue"),
        UserOfferCard: () => import("@/components/card/UserOfferCard.vue"),
    },
    computed: {
        ...mapGetters({
            GET_currentUserProfile: 'GET_currentUserProfile',
        }),
        computedSearch(){
            var onlyLetters = /^[a-zA-Z][a-zA-Z]*[a-zA-Z]$/;
            if(this.search.length > 2 && onlyLetters.test(this.search)){
                return this.search.trim() + "*"
            }else{
                return ""
            }
        }
    },
    methods: {
        async initialise() {
            this.loading = true;
            this.availableOffers = []
            this.getUser();
            await this.getUserOffers();
            await this.getOrgs();
            this.loading = false;
            //console.log(JSON.stringify(this.offers, null, 2))
            //console.log(this.stores[1].name)
        },
        async getUserOffers(){
            let uid = ''
            if (this.GET_currentUserProfile != null) {
                uid = this.GET_currentUserProfile.entityId
            } else if (localStorage.userToken) {
                uid = this.MIX_tokenToUser(localStorage.userToken).oid.replace(/-/g, "");
                //console.log("set user ID to "+this.stat.userId)
            } else {
                // console.log("No user logged in")
                this.loading = false
                return
            }
            return new Promise((resolve, reject) => {
              // this.REDIS_searchFor("user-offer", "", "", "", "", `${this.computedSearch} @userId:{${uid}} @status:{unclaimed}`)
              this.REDIS_searchFor("user-offer", "", "", "", "", `@userId:{${uid}} @status:{unclaimed}`)
              .then((result) => {
                this.offers = result.data.documents
                this.availableOffers = this.offers.filter(offer => !offer.deleted)
                // console.log("Offers: ", this.offers)
                resolve(result);
              })
              .catch((error) => {
                reject(error);
              });
            });
        },
        async getOrgs(){
          if(this.computedSearch !== "") {
            return new Promise((resolve, reject) => {
              this.REDIS_searchFor("org", "", "", "", "", `${this.computedSearch} @deleted:{false}`)
              .then((result) => {
                this.stores = result.data.documents;
                // console.log("StoresAll: ", this.stores) // searched store info
                this.stores = this.stores.map(store => store.entityId);
                // console.log("StoresE-ID: ", this.stores) // return entityID only
                this.availableOffers = this.availableOffers.filter(offer => this.stores.includes(offer.storeId));
                // console.log('Filtered Offers:', this.availableOffers)
                resolve(result);
              })
              .catch((error) => {
                reject(error);
              });
            });
          }
        },
        getUser(){
            if(this.GET_currentUserProfile != null){
                this.userFirstName = this.GET_currentUserProfile.firstName;
                this.userEntityId = this.GET_currentUserProfile.entityId;
            }else if(localStorage.userToken){
                // console.log("No user profile found, using local storage")
                //console.log(localStorage.userToken)
                this.userFirstName = this.MIX_tokenToUser(localStorage.userToken).given_name;
            }else{
                this.userFirstName = "Guest";
            }
        }
    },
    watch:{
        search:{
            handler: function(){
                this.initialise();
            },
            deep: true
        }
    },
    created(){
        this.initialise();
    }
};
</script>

<style>
.noboxshadow {
    box-shadow: none !important;

}

.v-carousel__controls {
    margin-top: 200px !important;
}
</style>

<template>
  <div class="paddingmobile">
    <div class="d-flex justify-center align-center pt-4">
      <v-img
        @click="MIX_go('/home')"
        src="@/assets/Big-Heart-Logo-Red.jpg"
        contain
        class="headericon"
      />
    </div>
    <v-container class="d-flex align-center justify-center pt-10">
      <v-row class="d-flex align-center justify-center">
        <v-col cols="12" class="">
          <div class="title pl-4">{{ $t("parking") }}</div>
        </v-col>

        <v-col cols="12" xs="12" sm="6">
          <v-row
            no-gutters
            class="primary white--text rounded-of"
            :class="$vuetify.breakpoint.width <= 700 ? 'mx-3' : 'ml-3'"
            style="height: 10em"
          >
            <v-col
              cols="4"
              class="primary darken-1 d-flex align-center justify-center rounded-of-left"
            >
              <div class="title text-center font-weight-bold">12345 Offer</div>
            </v-col>

            <v-col
              cols="8"
              class="pa-4 d-flex flex-column align-end justify-center"
              style="height: 10em"
            >
              <div class="subtitle-1 text-right">£1 for 1 hour | £2 for 2 hour</div>
              <div class="subtitle-1 text-right">£3 for 3 hour | £4 for 4 hour</div>
              <div class="subtitle-1 text-right">
                £5 all day in all city centre Council car parks.
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" xs="12" sm="6" class="">
          <div class="fill-height" :class="$vuetify.breakpoint.width <= 700 ? 'mx-3' : 'mr-3'">
            <v-btn
              href="https://secure.mipermit.com/root/Account/PayAndStayAdd.aspx"
              x-large
              depressed
              block
              class="primary white--text rounded-of pr-3"
              style="height: 10em"
            >
              <v-row>
                <v-col cols="8" class="d-flex align-center justify-center">
                  <div class="text-center white--text title removetexttransform">
                    MiPermit Login
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  class="pa-1 primary darken-1 rounded-of-right d-flex align-center justify-center"
                  style="height: 10em"
                >
                  <v-icon class="d-flex align-center justify-center" x-large>icons8-parking</v-icon>
                </v-col>
              </v-row>
            </v-btn>
          </div>

          <!--<div class="subtitle-3 lightgrey rounded-of pa-6 text-center"><b class="primary&#45;&#45;text">Coming Soon:</b> <br> Available Space Parking Data</div>-->
          <!-- <v-btn href="" x-large depressed block min-height="6em" class="primary white--text rounded-of biggerbutton removetexttransform">
                  <div class="text-center white--text title">Available Space Parking Data</div>
          </v-btn> -->
        </v-col>

        <v-col cols="12" class="d-flex justify-center">
          <v-row class="" no-gutters>
            <v-col cols="12" xs="12" sm="4" v-for="(carpark, index) in carparkData" :key="index">
              <ParkingCard :datadown="carpark" />
            </v-col>
          </v-row>
        </v-col>
        <!--TODO Add QR Back when needed-->
        <!--<v-col cols="12" xs="12" sm="6" class="d-flex flex-column align-center justify-center">-->
        <!--    <div class="title font-weight-bold primary&#45;&#45;text pb-5">Discount QR Code:</div>-->
        <!--    <qrcode-vue :value="value" :size="computedQrSize" level="H" />  -->
        <!--</v-col>-->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ParkingCard from "@/components/card/ParkingCard.vue";
// import QrcodeVue from 'qrcode.vue';
export default {
  name: "ParkingView",
  data: () => ({
    value: "SwanResi23",
    size: 400,
    carparkData: [
      {
        carparkName: "High Street",
        carparkType: "multi storey",
        carparkSpaces: 626,
        carparkSpacesDisabled: 28,
        carparkChargingSpaces: 6,
        carparkPaymentMethod: "Pay on foot",
        carparkAddress1: "Ivey Place",
        carparkPostcode: "SA1 1NU",
        carparkNotes: '6FT 6" (1.98M)',
      },
      {
        carparkName: "The Quadrant",
        carparkType: "multi storey",
        carparkSpaces: 485,
        carparkSpacesDisabled: 30,
        carparkChargingSpaces: 2,
        carparkPaymentMethod: "Pay on foot",
        carparkAddress1: "Wellington Street",
        carparkPostcode: "SA1 3QR",
        carparkNotes: '6FT 10" (2.08M)',
      },
      {
        carparkName: "St Davids Multi Storey",
        carparkType: "Multi Storey",
        carparkSpaces: 375,
        carparkSpacesDisabled: 38,
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "St Davids place",
        carparkPostcode: "sa1 3lw",
        carparkNotes: "NO lift, Max height 6ft, (1.83m)",
      },
      {
        carparkName: "Paxton Street",
        carparkType: "Surface",
        carparkSpaces: 293,
        carparkSpacesDisabled: 10,
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Bathurst street",
        carparkPostcode: "SA1 3SA",
        carparkNotes: "",
      },
      {
        carparkName: "East Burrows Road",
        carparkType: "Surface",
        carparkSpaces: 165,
        carparkSpacesDisabled: 9,
        carparkChargingSpaces: 2,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "East Burrows Road",
        carparkPostcode: "SA1 1RR",
        carparkNotes: "",
      },
      {
        carparkName: "The Strand",
        carparkType: "surface",
        carparkSpaces: 136,
        carparkSpacesDisabled: 11,
        carparkChargingSpaces: 1,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "The Strand",
        carparkPostcode: "SA1 2AE",
        carparkNotes: "motocyle bays",
      },
      {
        carparkName: "Northhampton Lane",
        carparkType: "Surface",
        carparkSpaces: 76,
        carparkSpacesDisabled: 4,
        carparkChargingSpaces: 6,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Northhampton Lane",
        carparkPostcode: "SA1 4EW",
        carparkNotes: "",
      },
      {
        carparkName: "Oxford Street",
        carparkType: "Surface",
        carparkSpaces: 62,
        carparkSpacesDisabled: 22,
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Singleton Street",
        carparkPostcode: "SA1 3AZ",
        carparkNotes: "",
      },
      {
        carparkName: "Pocketts Wharf",
        carparkType: "Surface",
        carparkSpaces: 49,
        carparkSpacesDisabled: 2,
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "East Burrows Road",
        carparkPostcode: "SA1 3XL",
        carparkNotes: "",
      },
      {
        carparkName: "Pell Street",
        carparkType: "Surface",
        carparkSpaces: 25,
        carparkSpacesDisabled: 3,
        carparkChargingSpaces: 2,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Pell Street",
        carparkPostcode: "sa1 3es",
        carparkNotes: "",
      },
      {
        carparkName: "Salubrious  place",
        carparkType: "Surface",
        carparkSpaces: 24,
        carparkSpacesDisabled: 0,
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Season tickets only Monday to Friday, pay and display sat and sun",
        carparkAddress1: "",
        carparkPostcode: "sa1 3lw",
        carparkNotes: "",
      },
      {
        carparkName: "YMCA Car park",
        carparkType: "Surface",
        carparkSpaces: 24,
        carparkSpacesDisabled: 2,
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Newton Street",
        carparkPostcode: "SA1 5JQ",
        carparkNotes: "",
      },
      {
        carparkName: "Worcester Place",
        carparkType: "Surface",
        carparkSpaces: 17,
        carparkSpacesDisabled: 2,
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Worcester place",
        carparkPostcode: "SA1 1HY",
        carparkNotes: "",
      },
      {
        carparkName: "Park street east",
        carparkType: "Surface",
        carparkSpaces: 15,
        carparkSpacesDisabled: 6,
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Park Street",
        carparkPostcode: "SA1 3DJ",
        carparkNotes: "",
      },
      {
        carparkName: "Christina Street",
        carparkType: "Street",
        carparkSpaces: "",
        carparkSpacesDisabled: "",
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Christina Street",
        carparkPostcode: "sa1 4ew",
        carparkNotes: "1 hour max stay",
      },
      {
        carparkName: "Castle street",
        carparkType: "Street",
        carparkSpaces: "",
        carparkSpacesDisabled: "",
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Castle Street",
        carparkPostcode: "sa1 5hz",
        carparkNotes: "1 hour max stay",
      },
      {
        carparkName: "Craddock street",
        carparkType: "Street",
        carparkSpaces: "",
        carparkSpacesDisabled: "",
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Craddock street",
        carparkPostcode: "sa1 3he",
        carparkNotes: "1 hour max stay",
      },
      {
        carparkName: "De-La-Beche",
        carparkType: "Street",
        carparkSpaces: "",
        carparkSpacesDisabled: "",
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "De-la-Beche",
        carparkPostcode: "sa1 3ey",
        carparkNotes: "1 hour max stay",
      },
      {
        carparkName: "Mansell Street",
        carparkType: "Street",
        carparkSpaces: "",
        carparkSpacesDisabled: "",
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Mansell Street",
        carparkPostcode: "sa1 5sw",
        carparkNotes: "1 hour max stay",
      },
      {
        carparkName: "Oxford Street",
        carparkType: "Street",
        carparkSpaces: "",
        carparkSpacesDisabled: "",
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Oxford street",
        carparkPostcode: "sa1 3bg",
        carparkNotes: "1 hour max stay",
      },
      {
        carparkName: "Plymouth street",
        carparkType: "Street",
        carparkSpaces: "",
        carparkSpacesDisabled: "",
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "Plymouth Street",
        carparkPostcode: "sa1 3qq",
        carparkNotes: "1 hour max stay",
      },
      {
        carparkName: "The Strand",
        carparkType: "Street",
        carparkSpaces: "",
        carparkSpacesDisabled: "",
        carparkChargingSpaces: 0,
        carparkPaymentMethod: "Pay and display / App",
        carparkAddress1: "The Strand",
        carparkPostcode: "sa1 2ag",
        carparkNotes: "2 hour max stay",
      },
      {
        carparkName: "Copr bay south",
        carparkType: "Multi storey",
        carparkSpaces: 347,
        carparkSpacesDisabled: 21,
        carparkChargingSpaces: 10,
        carparkPaymentMethod: "Pay on foot / ANPR, 12345 offer does not apply",
        carparkAddress1: "Oystermouth Road",
        carparkPostcode: "sa1 3bx",
        carparkNotes: "2.15 metre max height",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      GET_showDrawer: "GET_showDrawer",
      GET_showFilterDrawer: "GET_showFilterDrawer",
      GET_currentUserProfile: "GET_currentUserProfile",
      GET_authenticated: "GET_authenticated",
    }),
    computedQrSize() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 400;
      } else {
        return 200;
      }
    },
  },
  components: {
    ParkingCard,
    // QrcodeVue,
  },
};
</script>

<style>
.noboxshadow {
  box-shadow: none !important;
}

.v-carousel__controls {
  margin-top: 200px !important;
}
</style>

<template>
  <div class="paddingmobile">
    <div class="d-flex justify-center align-center pt-4">
      <v-img
        @click="MIX_go('/home')"
        src="@/assets/Big-Heart-Logo-Red.jpg"
        contain
        class="headericon"
      />
    </div>

    <v-container class="pt-10">
      <div class="introduction">
        <div class="header mb-4">
          <img src="@/assets/icons/billy_chip_logo.png" />
          <h4 class="title mb-0">{{ $t("billychip") }}</h4>
        </div>
        <p>{{ $t("billyinfo") }}</p>
      </div>
      <h4 class="title mb-4">Billy Chip</h4>
      <div class="chip-wrapper">
        <div class="chip-location-list">
          <div
            class="chip-location lightgrey"
            v-for="chip in chipLocations"
            :key="chip.id"
            @click="handleChipClick(chip)"
          >
            <BillyChipCard :data="chip" />
          </div>
        </div>
        <MapBoxView :locationList="chipLocations" :fly-to="flyToIndex" />
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BillyChipCard from "@/components/card/BillyChipCard.vue";
import MapBoxView from "@/components/MapboxView.vue";
// import QrcodeVue from 'qrcode.vue';
export default {
  name: "BillyChipView",
  components: {
    BillyChipCard,
    MapBoxView,
  },
  data: () => ({
    flyToIndex: null,
    chipLocations: [
      {
        description: "Cafe",
        id: 45467914,
        loc_lat: 51.62115,
        loc_long: -3.943299,
        name: "The Storyteller",
        phone: "",
        streetaddress: "40-42 Princess Way, Swansea SA1 5 HE, UK",
        distance: 0.11,
      },
      {
        description: "Cafe",
        id: 49412357,
        loc_lat: 51.619047,
        loc_long: -3.939364,
        name: "Socialdice UK Limited",
        phone: "",
        streetaddress: "43 Wind Street SA1 1EF, UK",
        distance: 0.12,
      },
      {
        description: "Cafe",
        id: 49412315,
        loc_lat: 51.622057,
        loc_long: -3.942304,
        name: "Jacks Kitchen",
        phone: "",
        streetaddress: "5-6 high street, Swansea SA1 1LE, UK",
        distance: 0.14,
      },
      {
        description: "Cafe",
        id: 49429639,
        loc_lat: 51.61812,
        loc_long: -3.93543,
        name: "Swansea Community Fridge (Goleudy)",
        phone: "",
        streetaddress: "Goleudy, The Customs House, Swansea SA1 1RG, UK",
        distance: 0.29,
      },
      {
        description: "Cafe",
        id: 49412350,
        loc_lat: 51.6269,
        loc_long: -3.94143,
        name: "Matthew's House",
        phone: "",
        streetaddress: "Matthew's House, Swansea SA1 1LW, UK",
        distance: 0.47,
      },
      {
        description: "Pet Food Supplier ",
        id: 49412446,
        loc_lat: 51.679956,
        loc_long: -4.129516,
        name: "Jollyes - The Pet People",
        phone: "",
        streetaddress:
          "Unit 8, Parc Pemberton Retail Park, Llanelli SA14 9UZ, UK",
        distance: 9.06,
      },
    ],
  }),
  methods: {
    handleChipClick(index) {
      this.flyToIndex = [index.loc_long, index.loc_lat];
    },
  },
  computed: {
    ...mapGetters({
      GET_showDrawer: "GET_showDrawer",
      GET_showFilterDrawer: "GET_showFilterDrawer",
      GET_currentUserProfile: "GET_currentUserProfile",
      GET_authenticated: "GET_authenticated",
    }),
  },
};
</script>

<style>
.noboxshadow {
  box-shadow: none !important;
}

.v-carousel__controls {
  margin-top: 200px !important;
}
</style>
<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 50px;
  }
}

.chip-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .chip-location-list {
    height: 500px;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 14px;
    .chip-location {
      margin-right: 5px;
      border-radius: 15px;
      padding: 10px;
    }
  }
}

@media (max-width: 768px) {
  .chip-wrapper {
    flex-direction: column-reverse;
    .chip-location-list {
      width: 100%;
    }
  }
}
</style>

<template>
  <v-mapbox
    :access-token="token"
    map-style="mapbox://styles/mapbox/streets-v11"
    :center="center"
    :zoom="14"
    id="map"
    ref="map"
  >
    <v-mapbox-navigation-control />
  </v-mapbox>
</template>

<script>
import mapboxgl from "mapbox-gl";

export default {
  name: "MapBoxView",
  props: {
    locationList: {
      type: Array,
      default: () => [],
    },
    flyTo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      token: process.env.VUE_APP_MAPBOX,
      center: [-3.943299, 51.62115], // Use loc_long for longitude
    };
  },
  methods: {
    flyToLocation(location) {
      const map = this.$refs.map.map;
      map.flyTo({
        center: { lng: location[0], lat: location[1] },
        zoom: 16,
      });
    },
  },
  mounted() {
    let map = this.$refs.map.map;

    map.on("load", () => {
      this.locationList.forEach((location) => {
        new mapboxgl.Marker()
          .setLngLat([location.loc_long, location.loc_lat]) // Use loc_long for longitude
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }).setHTML(
              `<h3 class="mt-4">${location.name}</h3><p class="mb-0">${location.description}</p>`
            )
          )
          .addTo(map);
      });
    });
  },
  watch: {
    flyTo(newVal) {
      this.flyToLocation(newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "mapbox-gl/dist/mapbox-gl.css";
@import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
#map {
  width: 100%;
  height: 500px; /* Adjust map height as needed */
  border-radius: 10px;
}

::v-deep.mapboxgl-popup-close-button {
  width: 20px;
  font-size: 18px;
  background-color: #ccc;
  border: none;
  color: #333;
  cursor: pointer;
  &:focus-visible {
    outline: none !important;
  }
}

::v-deep .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}

::v-deep a.mapboxgl-ctrl-logo {
  display: none;
}

@media (max-width: 768px) {
  #map {
    width: 100%;
    height: 250px;
  }
}
</style>

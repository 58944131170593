<template>
  <div class="paddingmobile">
    <v-overlay :opacity="0.1" :value="loading" color="lightgrey">
      <v-progress-circular indeterminate size="64" color="primary">
      </v-progress-circular>
    </v-overlay>
    <div class="d-flex justify-center align-center pt-4">
      <v-img
        @click="MIX_go('/admin/dashboard')"
        src="@/assets/Big-Heart-Logo-Red.jpg"
        contain
        class="headericon"
      />
    </div>
    <div class="pl-10 pr-10">
      <v-row>
        <v-col cols="6">
          <div class="title font-weight-black primary--text">
            {{ $t("offers") }}
          </div>
        </v-col>
      </v-row>
      <!-- YOUR OFFERS -->
      <v-row
        v-if="GET_currentUserProfile.level === 'shopadmin'"
        class="dense lightgrey rounded-of mb-6 pt-3 justify-space-between	"
      >
        <v-col cols="8" xs="6" sm="6" class="">
          <SearchField
            placeholder="Search"
            v-model="search"
            background="white"
            style="padding-left: 1em; max-width: 17em"
          />
        </v-col>
        <v-col
          cols="4"
          xs="6"
          sm="6"
          class="d-flex justify-end"
          style="padding-right: 1.5em;"
        >
          <AddOffer v-on:addedOffer="initialise" class="" :key="addOfferKey" style="margin-right: 0.25em;"/>
        </v-col>
        <v-col cols="12">
          <div
            class="title pl-4"
            style="margin-top: -0.6em; margin-bottom: -1.1em"
          >
            {{ $t("youroffers") }}
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" class="">
          <carousel
            height="auto"
            :paginationPadding="4"
            :perPage="1"
            :perPageCustom="[
              [768, 1],
              [1024, 2],
            ]"
            paginationActiveColor="#CC0200"
            paginationColor="#D9D9D9"
          >
            <slide v-for="(offer, index) in storeoffers" :key="index">
              <OfferCard
                :datadown="offer"
                class="lightgrey"
                v-on:edited="initialise()"
                editbutton
                deletebutton
                adminonly
              >
                <template v-slot:toptitle>{{ offer.offer }}</template>
                <template v-slot:bottomtitle>{{ offer.store }}</template>
              </OfferCard>
            </slide>
          </carousel>
        </v-col>
      </v-row>
      <!-- COMMUNITY OFFERS -->
      <v-row class="dense lightgrey rounded-of mb-6 pt-3">
        <v-col cols="8" xs="6" sm="6" class="">
          <SearchField
            placeholder="Search"
            v-model="search"
            background="white"
            style="padding-left: 1em; max-width: 17em"
          />
        </v-col>
        <v-col
          cols="4"
          xs="6"
          sm="6"
          class="d-flex justify-end"
          style="padding-right: 1.5em"
        >
          <AddOffer
            v-if="GET_currentUserProfile.level === 'superadmin'"
            v-on:addedOffer="initialise"
            class=""
            style="margin-right: 0.25em;"
          />
        </v-col>
        <v-col cols="12">
          <div
            class="title pl-4"
            style="margin-top: -0.6em; margin-bottom: -1.1em"
          >
            {{ $t("communityoffers") }}
          </div>
        </v-col>
        <v-col cols="12" xs="12" sm="12" class="">
          <carousel
            height="auto"
            :paginationPadding="4"
            :perPage="1"
            :perPageCustom="[
              [768, 1],
              [1024, 2],
            ]"
            paginationActiveColor="#CC0200"
            paginationColor="#D9D9D9"
          >
            <slide v-for="(offer, index) in offers" :key="index">
              <OfferCard
                :datadown="offer"
                class="lightgrey"
                v-on:edited="initialise()"
                v-on:deleted="initialise()"
                editbutton
                deletebutton
              >
                <template v-slot:toptitle>{{ offer.offer }}</template>
                <template v-slot:bottomtitle>{{ offer.store }}</template>
              </OfferCard>
            </slide>
          </carousel>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AdminOffersView",
  data: () => ({
    loading: false,
    offers: [],
    storeoffers: [],
    search: "",
    addOfferKey: 0,
    form: {
      name: "",
      email: "",
      password: "",
      testType: "",
      date: "",
      time: "",
    },
  }),
  computed: {
    ...mapGetters({
      GET_currentUserProfile: "GET_currentUserProfile",
    }),
    computedSearch() {
      var onlyLetters = /^[a-zA-Z][a-zA-Z]*[a-zA-Z]$/;
      if (this.search.length > 3 && onlyLetters.test(this.search)) {
        return this.search.trim() + "*";
      } else {
        return "";
      }
    },
    computedStoreSearch() {
      if (this.search.length > 3) {
        return this.search + "*";
      } else {
        return "";
      }
    },
  },
  components: {
    OfferCard: () => import("@/components/card/OfferCard.vue"),
    AddOffer: () => import("@/components/AddOffer.vue"),
  },
  methods: {
    async initialise() {
      this.loading = true;
      await this.getOffers();
      await this.getStoreOffers();
      this.addOfferKey++;
      this.loading = false;
      //console.log(JSON.stringify(this.offers, null, 2))
      //console.log(this.stores[1].name)
    },
    async getOffers() {
      return new Promise((resolve, reject) => {
        this.REDIS_searchFor(
          "offer",
          "",
          "",
          "createdDateTime",
          "desc",
          `${this.computedSearch} @deleted:{false}`
        )
          .then((result) => {
            this.offers = result.data.documents;
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getStoreOffers() {
      return new Promise((resolve, reject) => {
        // console.log(this.GET_currentUserProfile.orgId)
        this.REDIS_searchFor(
          "offer",
          "",
          "",
          "createdDateTime",
          "desc",
          `${this.computedStoreSearch} @orgId:{${this.GET_currentUserProfile.orgId}} @deleted:{false}`
        )
          .then((result) => {
            this.storeoffers = result.data.documents;
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  watch: {
    search: {
      handler: function () {
        this.initialise();
      },
      deep: true,
    },
  },
  created() {
    this.initialise();
  },
};
</script>

<style>
.noboxshadow {
  box-shadow: none !important;
}

.v-carousel__controls {
  margin-top: 200px !important;
}
</style>

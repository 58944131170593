<template>
  <div class="toilet-card lightgrey">
    <div class="header primary d-flex flex-column">
      <h4 class="title text-md-title font-weight-bold">Address</h4>
      <span class="font-weight-bold">{{ data.address || "N/A" }}</span>
      <a
        :href="'https://www.google.com/maps/search/?api=1&query=' + data.address"
        class="font-weight-bold white--text"
      >
        <v-icon class="white--text">icons8-place-marker</v-icon>
        {{ $t("openinmaps") }}
      </a>
    </div>
    <div class="body">
      <p class="mb-2"><b>Operator: </b>{{ data.operator || "N/A" }}</p>
      <p class="mb-0 font-weight-bold">Available Hours</p>
      <ul class="mb-2" v-if="data.opening_hours">
        {{
          data.opening_hours
        }}
        <!-- <li>Monday to Friday: {{ data.opening_hours.monday_to_friday }}</li>
        <li>Saturday: {{ data.opening_hours.saturday }}</li>
        <li>Sunday: {{ data.opening_hours.sunday }}</li> -->
      </ul>
      <p class="mb-0 font-weight-bold">Facilities</p>
      {{ data.facilities.replace(/\n/g, ", ") }}
      <!-- <span v-for="(facility, index) in data.facilities" :key="index"> {{ facility }}, </span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ToiletCard",
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.toilet-card {
  min-height: 100px;
  a {
    text-decoration: none;
  }

  .header {
    min-height: 140px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: white;
  }

  .header,
  .body {
    padding: 1rem;
  }

  .body ul {
    list-style: none;
    padding-left: 0;
  }
}
</style>

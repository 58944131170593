<template>
  <div class="paddingmobile" >
    <div class="d-flex justify-center align-center pt-4">
      <v-img
        @click="MIX_go('/home')"
        src="@/assets/Big-Heart-Logo-Red.jpg"
        contain
        class="headericon"
      />
    </div>

    <v-container class="py-10">
      <h4 class="title mb-4">{{ $t("toilet") }}</h4>
      <div class="toilet-location-list mt-4">
        <div class="toilet-location lightgrey" v-for="(toilet, index) in toiletList" :key="index">
          <ToiletCard :data="toilet" />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import toiletData from "@/toiletList.json";
import { mapGetters } from "vuex";
import ToiletCard from "@/components/card/ToiletCard.vue";
export default {
  name: "ToiletListView",
  data: () => ({
    toiletList: toiletData,
  }),
  computed: {
    ...mapGetters({
      GET_showDrawer: "GET_showDrawer",
      GET_showFilterDrawer: "GET_showFilterDrawer",
      GET_currentUserProfile: "GET_currentUserProfile",
      GET_authenticated: "GET_authenticated",
    }),
  },
  components: {
    ToiletCard,
  },
};
</script>

<style>
.noboxshadow {
  box-shadow: none !important;
}

.v-carousel__controls {
  margin-top: 200px !important;
}
</style>

<style lang="scss" scoped>
.toilet-location-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 14px;
  .toilet-location {
    border-radius: 15px;
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .toilet-location-list {
    width: 100%;
  }
}
</style>

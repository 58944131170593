<template>
  <div class="billy-chip-card">
    <div class="header d-flex flex-column">
      <h4 class="title text-md-title font-weight-bold">{{ data.name }}</h4>
      <span>Address: {{ data.streetaddress || "N/A" }}</span>
      <v-btn
        :href="'https://www.google.com/maps/search/?api=1&query=' + data.streetaddress"
        class="font-weight-bold primary white--text"
        depressed
      >
        <v-icon class="white--text">icons8-place-marker</v-icon>
        {{ $t("openinmaps") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "BillyChipCard",
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
.billy-chip-card {
  min-height: 80px;
  overflow: hidden;
  a {
    text-decoration: none;
  }
  cursor: pointer;

  .header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .header,
  .body {
    padding: 0.5rem;
  }

  .body ul {
    list-style: none;
    padding-left: 0;
  }
}
</style>
